import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAajKa8xz-RZGuhyhCDeGHl_Sli61-OeUk",
    authDomain: "whytefarmsweb.firebaseapp.com",
    projectId: "whytefarmsweb",
    storageBucket: "whytefarmsweb.appspot.com",
    messagingSenderId: "149750199117",
    appId: "1:149750199117:web:f6769b178428ffc4937d04",
    measurementId: "G-N7D8RFQ38Y"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
var auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
export const storage = getStorage(firebaseApp);
export { provider, auth, firebase };

export default db;


// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyAajKa8xz-RZGuhyhCDeGHl_Sli61-OeUk",
//     authDomain: "whytefarmsweb.firebaseapp.com",
//     projectId: "whytefarmsweb",
//     storageBucket: "whytefarmsweb.appspot.com",
//     messagingSenderId: "149750199117",
//     appId: "1:149750199117:web:f6769b178428ffc4937d04",
//     measurementId: "G-N7D8RFQ38Y"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);